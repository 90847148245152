import axios from 'axios';

// Prefix all axios requests with /api/
const instance = axios.create({
    baseURL: '/api/',
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    },
    withCredentials: true,
});

instance.interceptors.request.use(
    config => {
        config.headers.Authorization = 'Bearer ' + getCookie('token');
        return config;
    }
);

// Catch and handle Response Status'
instance.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
            // Catch Bad data responses
        if (error.response.status === 400) {
            // We can't use the showAlert here as most 400's get handled in the axios function catch. Otherwise we see showAlert twice.
            console.log((error.response.data.message) ? error.response.data.message : 'Sorry - Bad data was provided to this request');
            // Catch Unauthorised responses
        } else if (error.response.status === 401) {
            showAlert((error.response.data.message) ? error.response.data.message : 'Sorry - Your session has expired please refresh the page');
            // Catch Forbidden responses
        } else if (error.response.status === 403) {
            showAlert((error.response.data.message) ? error.response.data.message : 'Sorry - You are not authorised to make this request');
            // Catch Not Found responses
        } else if (error.response.status === 404) {
            showAlert((error.response.data.message) ? error.response.data.message : 'Sorry - We could not find the requested data');
            // Catch Server Errors
        } else if (error.response.status === 500) {
            showAlert((error.response.data.message) ? error.response.data.message : 'Sorry - An error occured making this request');
        }
        return Promise.reject(error);
    }
);
export default instance;
