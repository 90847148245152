/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
//import Vue from 'vue';
import Vue from 'vue';
window.Vue = Vue;

import axios from '@/classes/Axios.js';
window.Axios = axios;

require('./bootstrap');

import VueSelect from "vue-select";
import DatePicker from 'vuejs-datepicker';
import VueClipboards from 'vue-clipboards';
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'

Vue.use(VueClipboards);
Vue.use(Chartkick.use(Chart));

window.bootbox = require('bootbox');
window.moment = require('moment-timezone');
window.Bowser = require('bowser');
window.Vapor = require('laravel-vapor');

if (typeof VueSelect !== 'undefined') {
    Vue.component('v-select', VueSelect);
}
if (typeof DatePicker !== 'undefined') {
    Vue.component('vuejsDatepicker', DatePicker);
}

Vue.component('editor', require('@tinymce/tinymce-vue').default);
Vue.component('star-rating', require('vue-star-rating/dist/VueStarRating.umd.min.js').default);

// Tooltips in simple Bootstrap pages
$('#EmailLabel').tooltip();
